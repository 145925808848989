import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";
import { Companylogo } from "./GlobalData";
import { SETTINGS_CRUD_API } from "../hooks/APIHooks";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    // Fetch settings data from the API
    const fetchSettings = async () => {
      try {
        const response = await fetch(SETTINGS_CRUD_API);
        if (!response.ok) {
          throw new Error("Failed to fetch settings");
        }
        const data = await response.json();
        setSettings(data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    navigate("/");
  };

  // Hide Navbar on the login page
  const showNavbar = location.pathname !== "/";

  return (
    showNavbar && (
      <nav className="overflow-x-hidden bg-black z-[100] p-2 flex justify-between items-center w-full top-0 sticky">
        <div className="text-white text-xl font-bold">
          <a href="/dashboard" className="flex justify-center my-auto">
            <img
              src={Companylogo}
              alt="csdlogo"
              className="cursor-pointer w-8 h-auto mr-2"
            />
            {settings ? settings.companyName : "Loading..."} Backend
          </a>
        </div>

        <div
          onClick={handleLogout}
          className="hover:cursor-pointer text-white"
        >
          <IoMdLogOut className="text-3xl" />
        </div>
      </nav>
    )
  );
};

export default Navbar;
