import React, { useEffect, useState } from "react";
import axios from "axios";
import { Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL } from "../../hooks/APIHooks";
import { useAuth } from "../../hooks/GlobalProvider";
import { useParams } from "react-router-dom";

const SubscriptionTransationsDetails = () => {
  const { user } = useAuth();
  const { subscriptionId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (user && user.role === "admin") {
      axios
        .get(Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL)
        .then((response) => {
          const allTransactions = response.data;
          const filteredTransactions = allTransactions.filter(
            (trans) => trans.subscription_id === subscriptionId
          );
          setTransactions(filteredTransactions);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch transactions");
          setLoading(false);
        });
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user, subscriptionId]);

  // Filter transactions based on date range and status SUCCESS
  const filteredTransactions = transactions.filter((trans) => {
    const transDate = new Date(trans.created_at);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    return (
      trans.status === "SUCCESS" &&
      (!start || transDate >= start) &&
      (!end || transDate <= end)
    );
  });

  // Calculate statistics only for SUCCESS transactions in the date range
  const totalTransactions = filteredTransactions.length;
  const totalCompletedAmount = filteredTransactions.reduce(
    (sum, trans) => sum + parseFloat(trans.amount),
    0
  );
  const totalReceivedGold = filteredTransactions.reduce(
    (sum, trans) => sum + parseFloat(trans.goldgrams),
    0
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-20">
      <h1 className="text-2xl font-bold mb-4">Subscription Transactions</h1>
      
      {/* Date Filters */}
      <div className="mb-4 flex space-x-4">
        <input
          type="date"
          className="border p-2 rounded"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className="border p-2 rounded"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>

      {/* Summary Section */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <p className="text-lg font-semibold">
          Total Transactions: {totalTransactions}
        </p>
        <p className="text-lg font-semibold">
          Total Completed Amount: ₹{totalCompletedAmount.toFixed(2)}
        </p>
        <p className="text-lg font-semibold">
          Total Received Gold: {totalReceivedGold.toFixed(6)} grams
        </p>
      </div>

      {/* Transactions List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredTransactions.length > 0 ? (
          filteredTransactions.map((transaction) => (
            <div
              key={transaction.id}
              className="bg-white shadow-md p-4 rounded border border-gray-200"
            >
              <h2 className="text-lg font-bold mb-2">
                Transaction ID: {transaction.id}
              </h2>
              <p><strong>Subscription ID:</strong> {transaction.subscription_id}</p>
              <p><strong>User ID:</strong> {transaction.user_id}</p>
              <p><strong>Amount:</strong> ₹{transaction.amount}</p>
              <p><strong>Gold Grams:</strong> {transaction.goldgrams}</p>
              <p><strong>Status:</strong> {transaction.status}</p>
              <p><strong>Date:</strong> {new Date(transaction.created_at).toLocaleDateString()}</p>
              <p><strong>Email:</strong> {transaction.email}</p>
              <p><strong>Is Complete:</strong> {transaction.is_complete ? "Yes" : "No"}</p>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500">
            No transactions found.
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionTransationsDetails;