import React, { useEffect, useState } from "react";
import axios from "axios";
import { Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL } from "../../hooks/APIHooks";
import { useAuth } from "../../hooks/GlobalProvider";

const SubscriptionTransactions = () => {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [dateFilter, setDateFilter] = useState({
    start: "",
    end: new Date().toISOString().split("T")[0], // Sets today's date
  });

  const calculateGoldPriceAt = (amount, goldgrams) => {
    return ((amount / goldgrams) * 0.65).toFixed(2);
  };

  useEffect(() => {
    if (user && user.role === "admin") {
      axios
        .get(Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL)
        .then((response) => {
          setTransactions(response.data);
          setFilteredTransactions(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch transactions");
          setLoading(false);
        });
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    let filtered = transactions;
    if (statusFilter !== "ALL") {
      filtered = filtered.filter((trans) => trans.status === statusFilter);
    }
    if (dateFilter.start && dateFilter.end) {
      filtered = filtered.filter((trans) => {
        const transactionDate = new Date(trans.created_at);
        return (
          transactionDate >= new Date(dateFilter.start) &&
          transactionDate <= new Date(dateFilter.end)
        );
      });
    }
    setFilteredTransactions(filtered);
  }, [statusFilter, dateFilter, transactions]);

  // Filter transactions with status "SUCCESS"
  const successTransactions = filteredTransactions.filter(
    (trans) => trans.status === "SUCCESS"
  );

  // Calculate metrics only for "SUCCESS" transactions
  const totalReceivedGold = successTransactions.reduce(
    (sum, trans) => sum + parseFloat(trans.goldgrams || 0),
    0
  );
  const totalCompletedAmount = successTransactions.reduce(
    (sum, trans) => sum + parseFloat(trans.amount || 0),
    0
  );
  const totalTransactions = successTransactions.length;
  const avgPurchasePrice =
    totalTransactions > 0 ? (totalCompletedAmount / totalTransactions).toFixed(2) : 0;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4 mt-20">
      <h1 className="text-2xl font-bold mb-4">Subscription Transactions</h1>

      {/* Summary Metrics */}
      <div className="grid grid-cols-3 gap-4 text-center mb-4">
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Total Received Gold:</h3>
          <p className="text-xl font-bold">{totalReceivedGold.toFixed(6)} grams</p>
        </div>
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Total Completed Amount:</h3>
          <p className="text-xl font-bold">₹{totalCompletedAmount.toFixed(2)}</p>
        </div>
        <div className="shadow-lg p-4 rounded-md bg-yellow-500">
          <h3 className="font-semibold text-sm">Average Purchased Price:</h3>
          <p className="text-xl font-bold">
          ₹{avgPurchasePrice}
          </p>
        </div>
      </div>

     

      {/* Filters */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Status Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status Filter
          </label>
          <div className="flex space-x-4">
            {/* All Button */}
            <button
              className={`px-4 py-2 rounded-md shadow-sm ${
                statusFilter === "ALL"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => setStatusFilter("ALL")}
            >
              All
            </button>

            {/* Success Button */}
            <button
              className={`px-4 py-2 rounded-md shadow-sm ${
                statusFilter === "SUCCESS"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => setStatusFilter("SUCCESS")}
            >
              Success
            </button>

            {/* Failed Button */}
            <button
              className={`px-4 py-2 rounded-md shadow-sm ${
                statusFilter === "FAILED"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => setStatusFilter("FAILED")}
            >
              Failed
            </button>
          </div>
        </div>

        {/* Date Range Filters */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Date Range
          </label>
          <div className="flex space-x-4">
            {/* Start Date */}
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={dateFilter.start}
                onChange={(e) =>
                  setDateFilter((prev) => ({ ...prev, start: e.target.value }))
                }
              />
            </div>

            {/* End Date */}
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={dateFilter.end}
                onChange={(e) =>
                  setDateFilter((prev) => ({ ...prev, end: e.target.value }))
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Transactions List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredTransactions.length > 0 ? (
          filteredTransactions.map((transaction) => (
            <div
              key={transaction.id}
              className="bg-white shadow-md p-4 rounded border border-gray-200"
            >
              <h2 className="text-lg font-bold mb-2">
                Transaction ID: {transaction.id}
              </h2>
              <p>
                <strong>Subscription ID:</strong> {transaction.subscription_id}
              </p>
              <p>
                <strong>User ID:</strong> {transaction.user_id}
              </p>
              <p>
                <strong>Amount:</strong> {transaction.amount}
              </p>
              <p>
                {" "}
                <strong>Gold Grams:</strong> {transaction.goldgrams}
              </p>

              <p>
                <strong>Purchased Gold Price at:</strong>
                {calculateGoldPriceAt(
                  transaction.amount,
                  transaction.goldgrams
                )}{" "}
                ₹
              </p>
              <p>
                <strong>Status:</strong> {transaction.status}
              </p>
              <p>
                <strong>Date:</strong>{" "}
                {new Date(transaction.created_at).toLocaleDateString()}
              </p>
              <p>
                <strong>Email:</strong> {transaction.email}
              </p>
              <p>
                <strong>Is Complete:</strong>{" "}
                {transaction.is_complete ? "Yes" : "No"}
              </p>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500">
            No transactions found.
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionTransactions;
