import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import { FetchUserDetails } from "../../../hooks/APIHooks";

function StoreOrders() {
  const { userId } = useParams();
  const [storeOrders, setStoreOrders] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${FetchUserDetails}?id=${userId}`);
        const result = await response.json();
        if (result.status === "success") {
          setStoreOrders(result.data.store_orders || []);
        } else {
          setError(result.message || "No data found.");
        }
      } catch (err) {
        setError("Failed to fetch user details.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [userId]);
  

  // Define columns for the Digital Gold Orders table
  const columns = React.useMemo(
    () => [
      {
        Header: "Order Details",
        accessor: "orderDetails",
        Cell: ({ row }) => (
          <div className="px-2  text-[12px] ">
            <div>
              <p className="font-bold text-[10px]">Order ID</p>
              {row.original.id}
            </div>
            <div>
              <p className="font-bold text-[10px]">Merchant ID</p>
              {row.original.merchantId}
            </div>
            <div className="items-center">
              <p className="font-bold text-[10px] flex-shrink-0">
                Transaction ID:
              </p>
              <p className="text-[10px] break-all">{row.original.transactionId}</p>
            </div>
          </div>
        ),
      },
      {
        Header: "Amount & Status",
        accessor: "amountStatus",
        Cell: ({ row }) => (
          <div
            className={` text-[12px] p-2 ${
              row.original.status  }`}
          >
            <div>
              <p className="font-bold text-[10px]">Amount</p>
              {row.original.amount}
            </div>
            <div>
              <p className="font-bold text-[10px]">Status</p>
              {row.original.status}
            </div>
          
          </div>
        ),
      },
      {
        Header: "Order List",
        accessor: "orderlist",
        Cell: ({ row }) => (
          <div className="px-2 text-[14px]">
          <div >
          {row.original.orderlist}</div>

          <div className="text-[12px]">
              <p className="font-bold ">Created</p>
              {row.original.created_at}
            </div>
            </div>
        ),
      },
    ],
    []
  );

  // Initialize table instance with pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Use `page` for pagination instead of `rows`
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: storeOrders || [], // Ensure data is always an array
      initialState: { pageSize: 5 }, // Show 5 rows per page
    },
    usePagination
  );

  return (
    <div className="mb-8">
      {loading ? (
        <p className="text-center text-[12px]">Loading...</p>
      ) : error ? (
        <p className="text-yellow-700 text-center text-[12px]">{error}</p>
      ) : (
        <div className="bg-white shadow-lg rounded-lg">
          <h2 className="text-lg font-bold text-gray-800 mb-2 text-center text-[12px]">
          Store Orders
          </h2>
          {storeOrders.length > 0 ? (
            <>
            <table
  {...getTableProps()}
  className="table-auto w-full border border-gray-300 text-[12px]"
>
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th
            {...column.getHeaderProps()}
            className="border px-4 py-2 text-left text-[12px]"
          >
            {column.render("Header")}
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);

      // Get the status for background color logic
      const status = row.original.status;
      const rowBgColor =
        status === "FAILED"
          ? "bg-yellow-700 text-white"
          : status === "COMPLETED"
          ? "bg-yellow-500"
          : "";

      return (
        <tr
          {...row.getRowProps()}
          className={`border-t text-[12px] ${rowBgColor}`}
        >
          {row.cells.map((cell) => (
            <td
              {...cell.getCellProps()}
              className="border text-[12px]"
            >
              {cell.render("Cell")}
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
</table>

{/* Pagination Controls */}
<div className="flex justify-between items-center mt-4 space-x-4 p-3">
  <button
    onClick={() => previousPage()}
    disabled={!canPreviousPage}
    className="py-2 px-4 bg-yellow-500 rounded-md disabled:opacity-50 hover:bg-yellow-600"
  >
    Previous
  </button>
 
  <button
    onClick={() => nextPage()}
    disabled={!canNextPage}
    className="py-2 px-4 bg-yellow-500 rounded-md disabled:opacity-50 hover:bg-yellow-600"
  >
    Next
  </button>
</div>


            </>
          ) : (
            <p className="text-[12px]">No digital gold orders found.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default StoreOrders;
