import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FetchUserDetails } from "../../hooks/APIHooks";

import DigitalGoldOrders from "./tables/DigitalGoldOrders";
import StoreOrders from "./tables/StoreOrders";
import GoldSmithOrders from "./tables/GoldSmithOrders";
import ReferralStoreOrders from './tables/referralStoreOrders'

function UserDetails() {
  const { userId } = useParams(); // Get the userId from URL params
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${FetchUserDetails}?id=${userId}`);
        const result = await response.json();

        if (result.status === "success") {
          setData(result.data.user_details);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError("Failed to fetch user details.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  return (
    <div className="mb-8 px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 text-center sm:text-left">
        User Details
      </h1>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <div className="space-y-4">
          {/* User Info Orders */}
          <div className="grid grid-cols-2 lg:grid-cols-3">
            <div className="bg-white shadow-lg rounded-lg p-4">
              <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                User Info
              </h2>
              <div className="text-[14px] grid grid-cols-2">
                <p>
                  <strong>ID/Name:</strong> {data?.id} / {data?.name}
                </p>
                <p>
                  <strong>Phone:</strong> {data?.phonenumber}
                </p>
                <p>
                  <strong>Role:</strong> {data?.role}
                </p>
                <p>
                  <strong>Sex:</strong> {data?.sex}
                </p>
                <p>
                  <strong>Address:</strong> {data?.address}
                </p>
                <p>
                  <strong>Verified:</strong> {data?.is_active}
                </p>
                <p>
                  <strong>Created At:</strong> {data?.created_at}
                </p>
                <p>
                  <strong>Updated At:</strong> {data?.updated_at}
                </p>
                <p>
                  <strong>Email:</strong> {data?.emailaddress}
                </p>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-4">
              <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                User Currency Info
              </h2>
              <div className="text-sm space-y-2">
                <p>
                  <strong>Digital Currency:</strong> {data?.gold_grams || "N/A"}
                </p>
                <p>
                  <strong>Remarks:</strong> {data?.remarks || "N/A"}
                </p>
              </div>
            </div>
          </div>

          {/* Digital Gold Orders */}
          <DigitalGoldOrders />
          {/* Store Orders */}
          <StoreOrders />

          {/* GoldSmith Orders */}
          <GoldSmithOrders />

          {/* referralStore Orders */}
          <ReferralStoreOrders/>
        </div>
      )}
    </div>
  );
}

export default UserDetails;
