import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/GlobalProvider";
import {
  USER_DELETE,
  USER_CURRENCY_ANTS,
  CRUDUsers,
} from "../../hooks/APIHooks";
import "tailwindcss/tailwind.css";
import { useNavigate } from "react-router-dom";

function NewUsers() {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // Search filter state
  const navigate = useNavigate(); // for navigation
  const pageSize = 10;

  useEffect(() => {
    if (user && user.role === "admin") {
      const fetchUsers = async () => {
        try {
          const [usersResponse, currencyResponse] = await Promise.all([
            fetch(CRUDUsers, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ role: user.role }),
            }),
            fetch(USER_CURRENCY_ANTS),
          ]);

          const usersData = await usersResponse.json();
          const currencyData = await currencyResponse.json();

          if (usersData.success) {
            const mergedData = usersData.users.map((u) => ({
              ...u,
              ants:
                currencyData.find((c) => c.emailaddress === u.emailaddress)
                  ?.ants || 0,
              gold_grams:
                currencyData.find((c) => c.emailaddress === u.emailaddress)
                  ?.gold_grams || 0,
            }));
            setUsers(mergedData);
          } else {
            setError("Failed to fetch users");
          }
        } catch {
          setError("Error fetching data");
        } finally {
          setLoading(false);
        }
      };
      fetchUsers();
    } else {
      setError("Unauthorized access");
      setLoading(false);
    }
  }, [user]);

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this user?")) return;

    try {
      const response = await fetch(USER_DELETE, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
      });

      const result = await response.json();
      if (result.success) {
        setUsers(users.filter((u) => u.id !== id));
      } else {
        alert("Failed to delete user");
      }
    } catch {
      alert("Error deleting user");
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/users/user-details/${userId}`); // Navigate to the user details page with id
  };

  const filteredUsers = users.filter((u) =>
    u.phonenumber?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = React.useMemo(() => {
    const start = currentPage * pageSize;
    return filteredUsers.slice(start, start + pageSize);
  }, [filteredUsers, currentPage]);

  return (
    <div className="p-4">
      <h1 className="text-lg font-bold mb-4 text-center">All Users</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by phone number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      {error && <p className="text-red-500">{error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="overflow-hidden shadow rounded-lg">
            <table className="min-w-full bg-white border border-gray-200 text-sm">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left font-medium text-gray-700">
                    ID/Name
                  </th>
                  <th className="px-4 py-2 text-left font-medium text-gray-700">
                    Phone
                  </th>

                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {paginatedData.map((u) => (
                  <tr
                    key={u.id}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleUserClick(u.id)}
                  >
                    <td className="px-2">
                      <p className="font-semibold">{u.id} - {u.name}</p>
                      <p className="text-sm text-gray-500">{u.emailaddress}</p>
                      <p className=" bg-yellow-500 px-2 text-[12px]">
                        Ants: {u.ants} | Gold Grams: {u.gold_grams}
                      </p>
                    </td>
                    <td className="px-2">{u.phonenumber || "N/A"}</td>

                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredUsers.length === 0 && (
            <p className="text-center mt-2">No users found.</p>
          )}
          <div className="flex justify-between items-center mt-4 space-x-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
              disabled={currentPage === 0}
              className="py-2  bg-yellow-300 rounded-md disabled:opacity-50 hover:bg-yellow-500"
            >
              Previous
            </button>
            <span className="flex-grow text-center text-[12px] sm:text-base w-1/2">
              Page {currentPage + 1} of {Math.ceil(filteredUsers.length / pageSize)}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) =>
                  Math.min(prev + 1, Math.ceil(filteredUsers.length / pageSize) - 1)
                )
              }
              disabled={
                currentPage ===
                Math.ceil(filteredUsers.length / pageSize) - 1
              }
              className="py-2 bg-yellow-500 rounded-md disabled:opacity-50 hover:bg-yellow-600"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewUsers;
