// ApiUrl.js
const USER_CRUD_API ="https://admin.batchugold.com/apis/Fetch_Signin.php";

const SETTINGS_CRUD_API ="https://admin.batchugold.com/apis/Settings_CRUD.php"

const FetchUserDetails ="https://admin.batchugold.com/apis/users/Fetch_userDetails.php"

const FetchMedia ="https://admin.batchugold.com/apis/assets/media.php";


const USER_DELETE= "https://admin.batchugold.com/apis/users/deleteUser.php";
const USER_CURRENCY_ANTS= "https://admin.batchugold.com/apis/USER_CURRENCY_ANTS.php";
const CRUDUsers= "https://admin.batchugold.com/apis/users/CRUDUsers.php";

const Admin_CRUD_PRODUCT_POST="https://admin.batchugold.com/apis/Store/ProductPost.php";
const GOLD_LIVE_PRICE= "https://admin.batchugold.com/apis/LiveGoldApi.php"
const Admin_CRUD_TRANSACTIONS_URL = "https://admin.batchugold.com/apis/CRUDTransactions.php";

const Admin_CRUD_SUBSCRIPTION_URL = "https://admin.batchugold.com/apis/Subscriptions/CRUDSubscription.php";
const Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL="https://admin.batchugold.com/apis/Subscriptions/CRUDSubscriptionTransations.php";




export { USER_CRUD_API,SETTINGS_CRUD_API,

    GOLD_LIVE_PRICE,

    Admin_CRUD_TRANSACTIONS_URL,
    Admin_CRUD_SUBSCRIPTION_URL,Admin_CRUD_SUBSCRIPTION_TRANSATIONS_URL,Admin_CRUD_PRODUCT_POST,
    USER_DELETE,USER_CURRENCY_ANTS,CRUDUsers,
    
    FetchUserDetails,FetchMedia
};
