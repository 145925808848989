import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import { FetchUserDetails } from "../../../hooks/APIHooks";

function ReferralStoreOrders() {
  const { userId } = useParams();
  const [referralStoreOrders, setreferralStoreOrders] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${FetchUserDetails}?id=${userId}`);
        const result = await response.json();
        if (result.status === "success") {
            setreferralStoreOrders(result.data.referral_store_orders);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError("Failed to fetch user details.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [userId]);

  // Define columns for the Store Orders table
  const columns = React.useMemo(
    () => [
      {
        Header: "Order Details",
        accessor: "orderDetails",
        Cell: ({ row }) => (
          <div className="space-y-1 text-sm">
            <div>Order ID: {row.original.id}</div>
            <div>Merchant ID: {row.original.merchantId}</div>
            <div>Transaction ID: {row.original.transactionId}</div>
            <div>Email: {row.original.email}</div>
            <div>Address: {row.original.message}</div>
          </div>
        ),
      },
      {
        Header: "Amount & Status",
        accessor: "amountStatus",
        Cell: ({ row }) => (
          <div className="space-y-1 text-sm">
            <div>Amount: {row.original.amount}</div>
            <div>Status: {row.original.status}</div>
            <div>Created At: {row.original.created_at}</div>
          </div>
        ),
      },
      {
        Header: "orderlist",
        accessor: "orderlist",
        Cell: ({ row }) => <div className="text-sm">{row.original.orderlist}</div>,
      },
    ],
    []
  );

  // Initialize table instance with pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Use `page` for pagination instead of `rows`
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: referralStoreOrders,
      initialState: { pageSize: 5 }, // Show 10 rows per page
    },
    usePagination
  );

  return (
    <div className="mb-8 px-4 sm:px-6 lg:px-8">
      {loading ? (
        <p className="text-center text-sm">Loading...</p>
      ) : error ? (
        <p className="text-red-500 text-center text-sm">{error}</p>
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-4">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center text-sm">
          referral Store Orders
          </h2>
          {referralStoreOrders.length > 0 ? (
            <>
              <table
                {...getTableProps()}
                className="table-auto w-full border border-gray-300 text-sm"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className="border px-4 py-2 text-left text-sm"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {  // Use `page` instead of `rows` here
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="border-t text-sm">
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            className="border px-4 py-2 text-sm"
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="flex justify-between items-center mt-4 text-sm">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <span>
                  Page {0 + 1} of {pageCount}
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <p className="text-sm">No Store Orders found.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ReferralStoreOrders;
